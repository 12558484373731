import React from "react";
import styled from "styled-components";
import Button from "../../Utils/Button";
import BackTop from "../../Assets/back_top_discover.webp";
import BackBottom from "../../Assets/back_bottom_discover.webp";
import "../../css/typographyc.css";

const StyledDiscover = styled.div`
  background-color: var(--dark-back);
  color: white;
  padding: 0 20px;
  width: 100%;
  position: relative;
  h1 {
    font-family: "Russo_one";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    max-width: 500px;
  }
  h2 {
    font-family: "Noto_sans_regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 56px;
    margin-bottom: 100px;
    max-width: 670px;
  }
  h3 {
    font-family: "Noto_sans_regular";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    max-width: 420px;
    margin-bottom: 28px;
  }
  .container {
    position: relative;
    text-align: center;
    color: white;
    img {
      display: none !important;
    }
    .titles {
      max-width: 500px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 100px 36px;
    }
  }
  @media (min-width: 1200px) {
    h1 {
      font-size: 36px;
      line-height: 56px;
    }
    h2 {
      font-size: 24px;
      line-height: 44px;
    }
    h3 {
      font-size: 28px;
      line-height: 48px;
    }
    .container {
      .image_top {
        position: absolute;
        display: block !important;
        margin-left: 75%;
        margin-top: -15%;
      }
      .image_bottom{
        display: block !important;
        position: absolute;
        margin-top: 23%;
      }
      .titles {
        max-width: 634px;
        h1{
          padding-top: 100px;
        }
      }
    }
  }
  @media (min-width: 1400px) {
    .container {
      .image_top {
        margin-left: 75%;
        margin-top: -10%;
      }
      .image_bottom{
        display: block !important;
        position: absolute;
        margin-top: 20%;
      }
    }
  }
  @media (min-width: 1800px) {
    .container {
      .image_top {
        margin-left: 80%;
        margin-top: -8%;
      }
      .image_bottom{
        display: block !important;
        position: absolute;
        margin-top: 16%;
      }
    }
  }
  @media (min-width: 2000px) {
    .container {
      .image_top {
        margin-left: 83%;
        margin-top: -7%;
      }
      .image_bottom{
        display: block !important;
        position: absolute;
        margin-top: 14%;
      }
    }
  }
`;
export default function Discover() {
  return (
    <StyledDiscover>
      <div className="container">
        <img className="image_top" src={BackTop} alt="" />
        <img className="image_bottom" src={BackBottom} alt="" />
        <div className="titles">
          <h1>Has llegado al lugar con las mejores soluciones de seguridad</h1>
          <h2>
            ¿Estás interesado en comercializar alguna de nuestras AppSec tools?
            ¿Te gustaría ser nuestra mano derecha para alcanzar nuevos clientes?
          </h2>
          <h3>Descubre todas las posibilidades para hacer negocios.</h3>
          <Button textButton={"Descubrir"} link={"/contacto"} />
        </div>
      </div>
    </StyledDiscover>
  );
}
