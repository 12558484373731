import React from "react";
import styled from "styled-components";
import logo from "../../Assets/logo_datastream.webp";
import Phone from "../../Assets/icon_phone.svg";
import Mail from "../../Assets/icon_mail.svg";
import WWW from "../../Assets/icon_www.svg";
import Linkedin from "../../Assets/icon_linkedin.svg";
import useWindowSize from "../../hooks/useWindowSize";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "../../css/typographyc.css";

const StyledFooter = styled.div`
  background-color: var(--dark-back);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 16px;
  img {
    width: 135px;
    margin: 60px auto 0;
  }
  a {
    font-family: "Noto_sans_regular";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
  }
  h1 {
    font-family: "Russo_one";
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.02em;
    font-weight: bold;
  }
  .wrapper__blocks {
    .suscrib_block {
      margin-top: 52px;
      
      h1 {
        font-size: 16px;
      }
      input {
        width: 100%;
        max-width: 600px;
        height: 40px;
        margin-top: 20px;
        border-radius: 5px;
        padding: 10px;
        border: none;
        
        font-family: "Monserrat";
        :last-child{
          background-color: #121212;
          color: #0065FF;
          border: 1px solid #0065FF;
          font-size: 14px;
          line-height: 17px;
          font-weight: 700;
        }
      }
    }
    .first__block__info {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      a {
        color: white;
        text-decoration: underline;
      }
    }
    .second__block__info {
      margin-top: 48px;
      a{
        color: white;
        text-decoration: underline;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        margin-bottom: -5px;
        margin-top: 10px;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .sub {
      a {
        text-decoration: underline;
      }
      margin-bottom: 60px;
    }
  }
  hr {
    display: flex;
    margin: 0 5%;
    border: 0.5px solid white;
    margin-bottom: 30px;
  }
  .last__info__container {
    display: flex;
    flex-direction: column;
    p {
      text-align: center;
        font-family: "Noto_sans_regular";
    }
    .terms_and_conditions {
      display: flex;
      justify-content: space-around;
      margin-top: 16px;
      margin-bottom: 20px;
      a {
        color: white;
      }
    }
  }
`;
const StyledFooterDesktop = styled.div`
  background-color: var(--dark-back);
  color: white;
  display: flex;
  flex-direction: column;
  padding: 60px 30px 0;

  h1 {
    font-family: "Russo_one";
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.02em;
    font-weight: bold;
  }
  a {
    font-family: "Noto_sans_regular";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: white;
  }
  .info_top {
    display: flex;
    .logo_image {
      display: flex;
      width: 300px;
    }
    .container_info_bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 40px;
      .suscrib_block {
      max-width: 300px;
      h1 {
        font-size: 16px;
      }
      input {
        width: 100%;
        max-width: 300px;
        height: 40px;
        margin-top: 20px;
        border-radius: 5px;
        padding: 10px;
        border: none;
        
        font-family: "Monserrat";
        :last-child{
          background-color: #121212;
          color: #0065FF;
          border: 1px solid #0065FF;
          font-size: 14px;
          line-height: 17px;
          font-weight: 700;
        }
      }
    }
      .link_info {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        p{
        font-family: "Noto_sans_regular";
       }
        :nth-child(2) {
          margin-left: 50px;
        }
        :nth-child(3) {
          margin-left: 20px;
        }
        :nth-child(4) {
          margin-left: 10px;
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 12px;
          margin-bottom: -5px;
          margin-top: 10px;
        }
      }
      .sub {
        a {
          text-decoration: underline;
          margin-top: 15px;
        }
      }
    }
  }
  .info_bottom {
    margin-top: 27px;
    hr {
      display: flex;
      border: 0.5px solid white;
    }
    .last_links {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      font-family: "Noto_sans_regular";
      p{
        font-family: "Noto_sans_regular";
      }
      .row_links {
        width: 400px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
`;
const Footer = () => {
  const windowSize = useWindowSize();
  return windowSize < 1200 ? (
    <>
      <StyledFooter id="contact-scroll">
        <img src={logo} alt="img_logo" />
        <div className="wrapper__blocks">
          <div className="suscrib_block">
            <h1>
              Suscríbete a nuestro newsletter para futuros eventos y noticias
            </h1>
            <form>
              <input type="text" placeholder="Ingresa tu email" />
              <input type="submit" value={"Suscribirme"} />
            </form>
          </div>
          <div className="first__block__info">
            <a href="/entrenamientos">Entrenamientos</a>

            <a href="/eventos">Eventos</a>
          </div>
          <div className="second__block__info">
            <h1>Contact</h1>
            <a>
              <img src={Phone} alt="icon_logo" />
              +52 55 8946 9532
            </a>
            <a href="mailto: lets-do-business@datastream.com.mx">
              <img src={Mail} alt="icon_logo" />
              lets-do-business@datastream.com.mx
            </a>
            <a>
              <img src={WWW} alt="icon_logo" />
              www.datastream.com.mx
            </a>
          </div>
          <div className="second__block__info sub">
            <h1>Social networks</h1>
            <a>
              <img src={Linkedin} alt="icon_logo" />
              Our News Linkedin page
            </a>
            <a>
              <img src={Linkedin} alt="icon_logo" />
              Our News Linkedin page
            </a>
          </div>
        </div>
        <hr />
        <div className="last__info__container">
          <p>Datastream © Copyright 2022 - All rights reserved</p>
          <div className="terms_and_conditions">
            <a href="">Terms and Conditions</a>
            <a href="">Privacy Policy</a>
          </div>
        </div>
      </StyledFooter>
    </>
  ) : (
    <>
      <StyledFooterDesktop>
        <div className="info_top">
          <div className="logo_image">
            <img src={logo} alt="logo_image" />
          </div>
          <div className="container_info_bottom">
          <div className="suscrib_block">
            <h1>
              Suscríbete a nuestro newsletter para futuros eventos y noticias
            </h1>
            <form>
              <input type="text" placeholder="Ingresa tu email" />
              <input type="submit" value={"Suscribirme"} />
            </form>
          </div>
            <div className="link_info sub">
              <h1>DATASTREAM</h1>

              <a href="/entrenamientos">Entrenamientos</a>

              <a href="/eventos">Eventos</a>
            </div>
            <div className="link_info">
              <h1>Contact</h1>
              <p>
                <img src={Phone} alt="phone_icon" />
                +52 55 8946 9532
              </p>
              <a href="mailto: lets-do-business@datastream.com.mx">
                <img src={Mail} alt="phone_icon" />
                lets-do-business@datastream.com.mx
              </a>
              <p>
                <img src={WWW} alt="phone_icon" />
                www.datastream.com.mx
              </p>
            </div>
            <div className="link_info sub">
              <h1>Social networks</h1>
              <a href="https://www.linkedin.com/company/datastreammx/">
                <img src={Linkedin} alt="phone_icon" />
                Our Linkedin
              </a>
              <a href="https://www.linkedin.com/company/datastreammx/">
                <img src={Linkedin} alt="phone_icon" />
                Our News Linkedin page
              </a>
            </div>
          </div>
        </div>
        <div className="info_bottom">
          <hr />
          <div className="last_links">
            <p>Datastream © Copyright 2022 - All rights reserved</p>
            <div className="row_links sub">
              <a>Terms and Conditions</a>
              <a>Privacy Policy</a>
            </div>
          </div>
        </div>
      </StyledFooterDesktop>
    </>
  );
};

export default Footer;
