import React from "react";
import styled from "styled-components";
import "../../css/typographyc.css";

const StyledAplication = styled.div`
  background: linear-gradient(180deg, #1e1e1e 0%, #121212 100%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding-top: 72px;
  h1 {
    font-family: "Russo_one";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    padding-top: 72px;
    color: white;
  }
  .container_cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    :last-child {
      padding-bottom: 100px;
    }
  }
  @media (min-width: 1200px) {
    padding-top: 120px;
    h1 {
      font-size: 32px;
      padding-bottom: 68px;
    }
    .container_cards {
      flex-direction: row;
      padding-bottom: 100px;
    }
  }
`;

const StyledCard = styled.div`
  background-color: #252525;
  width: 328px;
  height: 368px;
  border-radius: 8px;
  margin-top: 70px;
  display: flex;
  color: white;
  padding: 0 24px;
  margin-top: 100px;
  margin-bottom: 50px;
  .principal_title {
    position: absolute;
    width: 300px;
    height: 50px;
    margin-top: -180px;
  }
  .image_top {
    position: absolute;
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -8px;
    .image_test {
      position: absolute;
    }
  }
  .info_card {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    img {
      width: 20px;
      height: 20px;
    }
    p {
      font-family: "Noto_sans_regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-left: 8px;
      margin-top: 40px;
    }
  }
  @media (min-width: 1200px) {
    margin-left: 20px;
    margin-top: 110px;
    .principal_title {
      width: 300px;
      height: 50px;
      margin-top: -220px;
    }
    .info_card {
      p {
        font-size: 16px;
      }
    }
  }
  @media (min-width: 1400px) {
    width: 428px;
    height: 468px;
    margin-left: 20px;
    margin-top: 110px;
    .principal_title {
      width: 400px;
    }
    .image_top {
      margin-left: 0px;
      img {
        width: 380px;
        :last-child {
          width: 50%;
        }
      }
    }
    .info_card {
      margin-top: 10px;
      p {
        margin-top: 20px;
      }
    }
  }
`;
export default function EndPointCards() {
  return (
    <StyledAplication>
      <div className="container_cards">
        {cardArray.map((e, idx) => {
          return (
            <StyledCard key={idx}>
              <>
                <div className="principal_title">
                  <h1>{e.principalTitle}</h1>
                </div>
                <div className="image_top">
                  <img src={e.triangleImage} alt="triangle_icon" />
                  <img
                    className="image_test"
                    src={e.imageIcon}
                    alt="icon_logo"
                  />
                </div>
                <div className="info_card">
                  <img src={e.check} alt="check_icon" />
                  <p>{e.text}</p>
                </div>
              </>
            </StyledCard>
          );
        })}
      </div>
    </StyledAplication>
  );
}

const cardArray = [
  {
    triangleImage: require("../../Assets/triangle_card_white.webp").default,
    imageIcon: require("../../Assets/carbon_logo.webp").default,
    check: require("../../Assets/icon_circle_check.webp").default,
    text: "Protección de endpoints, servers y máquinas virtuales ante malware y amenazas de nueva generación.",
    principalTitle: "EndPoint Protection",
  },
  {
    triangleImage: require("../../Assets/triangle_card_white.webp").default,
    imageIcon: require("../../Assets/perception_logo.webp").default,
    check: require("../../Assets/icon_circle_check.webp").default,
    text: "Aislamiento, detección y remediación rápida de todos los ataques a través de correos electrónicos, navegadores web y canales de cloud collaboration.",
    principalTitle: "AntiPishing Protection",
  },
];
