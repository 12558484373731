import React from "react";
import styled from "styled-components";
import "../../css/typographyc.css";

const StyledAplication = styled.div`
  background: linear-gradient(180deg, #1e1e1e 0%, #121212 100%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  h1 {
    font-family: "Russo_one";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    padding-top: 72px;
    color: white;
  }
  .container_cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  @media (min-width: 1200px) {
    h1 {
      font-size: 36px;
      padding-bottom: 68px;
    }
    .container_cards {
      flex-direction: row;
      padding-bottom: 100px;
    }
  }
`;

const StyledCard = styled.div`
  background-color: #252525;
  width: 328px;
  height: 368px;
  border-radius: 8px;
  margin-top: 70px;
  display: flex;
  color: white;
  padding: 0 24px;
  margin-top: 100px;
  :last-child{
    margin-bottom: 100px;
  }
  .image_top {
    position: absolute;
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -8px;
    .image_test {
      position: absolute;
    }
  }
  .info_card {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-top: 90px;
    img {
      width: 20px;
      height: 20px;
    }
    p {
      font-family: "Noto_sans_regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-left: 8px;
    }
    .info_item {
      display: flex;
      width: 285px;
      margin-top: 10px;
    }
  }
  @media (min-width: 1200px) {
    margin-left: 20px;
    margin-top: 110px;
    :last-child{
    margin-bottom: 0px;
  }
  }
  @media (min-width: 1400px) {
    width: 428px;
    height: 468px;
    margin-left: 20px;
    margin-top: 110px;
    .image_top {
      margin-left: 0px;
      img {
        width: 380px;
        :last-child{
          width: 50%;
        }
      }
    }
  
    .info_card {
      margin-top: 130px;
    }
  }
`;
export default function AplicationCards() {
  return (
    <StyledAplication>
      <h1>Seguridad de aplicaciones</h1>

      <div className="container_cards">
        {cardArray.map((e, idx) => {
          const text1 = e.text?.toString().split("\n")[0];
          const text2 = e.text?.toString().split("\n")[1];
          const text3 = e.text?.toString().split("\n")[2];
          const text4 = e.text?.toString().split("\n")[3];
          const text5 = e.text?.toString().split("\n")[4];
          const text6 = e.text?.toString().split("\n")[5];
          return (
            <StyledCard key={idx}>
              <>
                <div className="image_top">
                  <img src={e.triangleImage} alt="triangle_icon" />
                  <img
                    className="image_test"
                    src={e.imageIcon}
                    alt="icon_logo"
                  />
                </div>
                <div className="info_card">
                  <div className="info_item">
                    <img src={e.check} alt="hola" />
                    <p>{text1}</p>
                  </div>
                  <div className="info_item">
                    <img src={e.check} alt="hola" />
                    <p>{text2}</p>
                  </div>
                  <div className="info_item">
                    <img src={e.check} alt="hola" />
                    <p>{text3}</p>
                  </div>
                  <div className="info_item">
                    <img src={e.check} alt="hola" />
                    <p>{text4}</p>
                  </div>
                  {text5 ? (
                    <div className="info_item">
                      <img src={e.check} alt="hola" />
                      <p>{text5}</p>
                    </div>
                  ) : null}
                  {text6 ? (
                    <div className="info_item">
                      <img src={e.check} alt="hola" />
                      <p>{text5}</p>
                    </div>
                  ) : null}
                </div>
              </>
            </StyledCard>
          );
        })}
      </div>
    </StyledAplication>
  );
}

const cardArray = [
  {
    triangleImage: require("../../Assets/triangle_card_white.webp").default,
    imageIcon: require("../../Assets/checkmark_logo.webp").default,
    check: require("../../Assets/icon_circle_check.webp").default,
    text: [
      "Escaneo de motores y cobertura de soluciones en cada fase del ciclo de desarrollo. \n Análisis y remediación de vulnerabilidades en el código. \n Busca estar cerca del desarrollador. \n Cuenta con servicio de apoyo, velocidad y tecnologías innovadoras.",
    ],
  },
  {
    triangleImage: require("../../Assets/triangle_card_white.webp").default,
    imageIcon: require("../../Assets/brigth_logo.webp").default,
    check: require("../../Assets/icon_circle_check.webp").default,
    text: "Análisis DAST de nueva generación con resultados ágiles. \n Sin falsos positivos e integrable al ciclo de desarrollo de las aplicaciones. \n Sencillo de usar. \n Escanea en minutos. \n Inventa nuevos escenarios de ataque específicos para tu app. \n  Interactúa con APIS y sus escaneos usan AI-powered engine.",
  },
];
