import React, { useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import Logo from "../../Assets/logo_datastream.webp";
import Menu from "../../Assets/menu_mobile.svg";
import Close from "../../Assets/icon_close.svg";
import styled from "styled-components";
import Button from "../../Utils/Button";
const StyledHeaderMobile = styled.div`
  background-color: var(--dark-back);
  .menu {
    padding: 10px;
    background-color: var(--dark-back);
    display: flex;
    justify-content: space-between;
  }

  img {
    max-width: 40px;
  }
  
`;

const StyledTest = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: var(--dark-back);
  .menu {
    padding: 10px;
    background-color: var(--dark-back);
    display: flex;
    justify-content: space-between;
  }

  img {
    max-width: 40px;
  }
  .links {
    padding: 10px;
    display: flex;
    flex-direction: column;
    a {
      color: white;
      margin-top: 12px;
      font-family: "Noto_sans_regular";
      font-size: 25px;
    }
    .button_blue{
      margin-top: 20px;
      width: 180px;
      p{
        font-size: 22px;
      }
    }
  }
`;
const StyledHeaderDesktop = styled.div`
  background-color: var(--dark-back);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  img {
    max-width: 70px;
  }
  a {
    color: white;
    font-family: "Noto_sans_regular";
  }
  a:active {
    font-weight: bold;
  }
  .content_links {
    width: 50%;
    display: flex;
    justify-content: space-around;
    .button_blue{
      width: 130px;
      height: 30px;
      margin: 0;
    }
  }
`;

const Header = () => {
  const windowSize = useWindowSize();
  const [showNav, setShowNav] = useState(false);
  return windowSize < 1200 ? (
    <>
      {showNav ? (
        <StyledTest>
          <div className="menu">
            <a href="/reseller">
              <img src={Logo} alt="Datastream" />
            </a>
            <img
              onClick={() => setShowNav(!showNav)}
              src={Close}
              alt=""
            />
          </div>
          <div className="links">
            <a href="/reseller">Inicio</a>
            <a href="/entrenamientos">Entrenamientos</a>
            <a href="/eventos">Eventos</a>
            <a href="/contacto">Contacto</a>
            <a onClick={() => setShowNav(!showNav)} href="/">
              <Button textButton={"I am a vendor"}/>
            </a>
          </div>
        </StyledTest>
      ) : (
        <StyledHeaderMobile>
          <div className="menu">
            <a href="/">
              <img src={Logo} alt="Datastream" />
            </a>
            <img
              onClick={() => setShowNav(!showNav)}
              src={Menu}
              alt=""
            />
          </div>
        </StyledHeaderMobile>
      )}
    </>
  ) : (
    <StyledHeaderDesktop>
      <a href="/reseller">
        <img src={Logo} alt="Datastream" />
      </a>

      <div className="content_links">
        <a href="/reseller">Inicio</a>
        <a href="/entrenamientos">Entrenamientos</a>
        <a href="/eventos">Eventos</a>
        <a href="/contacto">Contacto</a>
        <a onClick={() => setShowNav(!showNav)} href="/">
              <Button textButton={"I am a vendor"}/>
            </a>
      </div>
    </StyledHeaderDesktop>
  );
};

export default Header;
