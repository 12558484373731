import React from "react";
import Layout from "../components/layout-reseller";
import HomeVideoBack from "../Utils/HomeVideoBack";
import Discover from "../components/homereseller/Discover";
import AplicationCards from "../components/homereseller/AplicationCards";
import AplicationsInfo from "../components/homereseller/AplicationsInfo";
import EndPointCards from "../components/homereseller/EndPointCards";
import BannerStart from "../Utils/BannerStart";
import bannerMobile from "../Assets/back_banner_mobile.webp";
import bannerDesktop from "../Assets/back_banner_desktop.webp";
import VideoDesk from "../videos/Españoldesk.mp4";
import VideoMobile from "../videos/MobEspa.mp4";

export default function homereseller() {
  return (
    <div>
      <Layout>
        <HomeVideoBack videoUrl={VideoDesk} videoMobile={VideoMobile} />
        <Discover />
        <AplicationCards />
        <AplicationsInfo />
        <EndPointCards />
        <BannerStart
          bannerMobile={bannerMobile}
          bannerDesktop={bannerDesktop}
          buttonText={"Comenzar"}
          title={"¿Estás listo para hacer negocios?"}
        />
      </Layout>
    </div>
  );
}

export const Head = () => (
  <>
    <title>Datastream</title>
    <meta name="description" content="Our partners are already providers for some of the largest accounts in Mexico, for the public and private sectors." />
    <meta name="keywords" content="Datastream, Datastream mx"/>
  </>
)
