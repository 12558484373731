import React from "react";
import styled from "styled-components";
import backMobile from "../Assets/back_banner_start.png";
import backDesktop from "../Assets/back_colors.webp";
import Button from "./Button";
import "../css/typographyc.css";

const StyledBanner = styled.div`
  background-color: var(--dark-back);
    .container {
    position: relative;
    text-align: center;
    color: white;
    img {
      width: 100%;
    }
    .back_desktop {
      display: none;
    }
    .back_desktop2 {
      display: none;
    }
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    .banner_desktop {
      display: none;
    }
    img {
      width: 340px;
    }
    h1 {
      position: absolute;
      top: 25%;
      left: 45%;
      transform: translate(-50%, -50%);
      width: 250px;
      font-family: "Noto_sans_regular";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      text-align: left;
    }
    .blue_button {
      position: absolute;
      top: 55%;
      left: 35%;
      transform: translate(-50%, -50%);
    }
  }
  @media (min-width: 600px) {
    .centered {
      img {
        width: 450px;
      }
      h1 {
        left: 35%;
        font-size: 22px;
      }
      .blue_button {
        left: 28%;
      }
    }
  }
  @media (min-width: 992px) {
    .centered {
      img {
        width: 650px;
      }
      h1 {
        left: 35%;
        font-size: 28px;
        width: 400px;
      }
      .blue_button {
        left: 20%;
      }
    }
  }
  @media (min-width: 1200px) {
    .container {
      .container_back_mobile {
        display: none;
      }
      .container_back_desktop {
        display: flex;
        justify-content: space-evenly;
        margin-top: -100px;
        /* img{
          height: 100%;
          max-height: 900px;
        } */
        .back_desktop {
          display: block;
          transform: rotate(180deg);
        }
        .back_desktop2 {
          display: block;
        }
      }
      .centered {
        h1 {
          left: 25%;
          font-size: 28px;
        }
        .banner_desktop {
          display: block;
          height: 221px;
          min-width: 1000px;
        }
        .banner_mobile {
          display: none;
        }
      }
    }
  }
  @media (min-width: 1400px) {
    .container {
      .centered {
        h1 {
          font-size: 34px;
          width: 500px;
        }
        .banner_desktop {
          min-width: 1200px;
          height: 321px;
        }
      }
    }
  }
  @media (min-width: 1800px) {
    .container {
      .centered {
        .banner_desktop {
          min-width: 1600px;
        }
      }
    }
  }
`;
export default function BannerStart(props) {
  const { bannerMobile, bannerDesktop, title, buttonText } = props;
  return (
    <StyledBanner>
      <div className="container">
        <div className="container_back_mobile">
          <img className="back_mobile" src={backMobile} alt="back_mobile" />
        </div>
        <div className="container_back_desktop">
          <img className="back_desktop" src={backDesktop} alt="back_mobile" />
          <img className="back_desktop2" src={backDesktop} alt="back_mobile" />
        </div>
        <div class="centered">
          <img className="banner_mobile" src={bannerMobile} alt="banner_back" />
          <img
            className="banner_desktop"
            src={bannerDesktop}
            alt="banner_back"
          />
          <h1>{title}</h1>
          <div className="blue_button">
            <Button link={"/contacto"}  textButton={buttonText} />
          </div>
        </div>
      </div>
    </StyledBanner>
  );
}
