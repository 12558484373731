import React from "react";
import styled from "styled-components";
import "../../css/typographyc.css";
const StyledAplications = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 0 25px 72px;
  .container_aplications {
    display: flex;
    flex-direction: column;
    .info_card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    img {
      margin-bottom: 44px;
      margin-top: 72px;
    }
    h1 {
      font-family: "Noto_sans_regular";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 20px;
      max-width: 268px;
    }
    h2 {
      font-family: "Noto_sans_regular";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      max-width: 268px;
    }
  }
  @media (min-width: 1200px) {
    padding: 0 25px 77px;
    .container_aplications {
      flex-direction: row;
      justify-content: space-around;
      .info_card {
        img {
          height: 60px;
          margin-top: 77px;
        }
        h1 {
          font-size: 16px;
        }
        h2 {
          font-size: 14px;
        }
      }
    }
  }
`;
export default function AplicationsInfo() {
  return (
    <StyledAplications>
      <div className="container_aplications">
        {aplicationsArray.map((e, idx) => {
          return (
            <div className="info_card">
              <img src={e.image} alt="image_logo" />
              <h1>{e.title}</h1>
              <h2>{e.description}</h2>
            </div>
          );
        })}
      </div>
    </StyledAplications>
  );
}

const aplicationsArray = [
  {
    image: require("../../Assets/appknox_logo.webp").default,
    title:
      "Appknox es la solución de seguridad plug and play más poderosa del mundo.",
    description:
      "Appknox ayuda a los desarrolladores, investigadores de seguridad y empresas a construir un entorno seguro y protegido para sus aplicaciones móviles.",
  },
  {
    image: require("../../Assets/bridge_logo.webp").default,
    title:
      "Es una plataforma completa de seguridad para SAP que responde a todas las necesidades de seguridad de entornos empresariales.",
    description:
      "Detección de amenazas, Compliance, Seguridad en el código, Patch Management y Monitoreo en una sola plataforma intuitiva y extensible.",
  },
  {
    image: require("../../Assets/wib_logo.webp").default,
    title:
      "Plataforma de seguridad que brinda, visibilidad, monitoreo y protección para APIs.",
    description:
      "Wib ayuda a proteger las API durante todo el ciclo de, desde el código hasta las pruebas funcionales y la puesta en producción.",
  },
];
